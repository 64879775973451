var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-col',{staticClass:"pr-2 pb-1 pt-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"to-right text-right",attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.closeIcon))])],1)]}}])},[_c('span',[_vm._v("Cerrar")])])],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.agrupacionesItems,"label":"Agrupación","item-text":"value","item-value":"id","return-object":"","hide-details":"auto","rules":_vm.rules.required,"outlined":"","clearable":"","dense":""},on:{"change":function($event){return _vm.getGruposByAgrupacionId()}},model:{value:(_vm.agrupacionSelected),callback:function ($$v) {_vm.agrupacionSelected=$$v},expression:"agrupacionSelected"}})],1),_c('v-col',{staticClass:"py-0 pl-2 pr-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.gruposItems,"label":"Grupo de prácticas","item-text":"value","item-value":"id","return-object":"","hide-details":"auto","rules":_vm.rules.required,"outlined":"","clearable":"","dense":""},model:{value:(_vm.grupoSelected),callback:function ($$v) {_vm.grupoSelected=$$v},expression:"grupoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3"}},[_c('v-menu',{ref:"fecha-mascara-desde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":_vm.rules.required.concat(
                    _vm.rules.validDate,
                    new Date(_vm.parseDateToIso(_vm.fechaMascaraSelected)) <=
                      new Date(_vm.parseDateToIso(_vm.fechaMascaraSelectedHasta)) ||
                      'Formato incorrecto'
                  )},on:{"blur":function($event){_vm.fechaMascara = _vm.parseDateToIso(_vm.fechaMascaraSelected)}},model:{value:(_vm.fechaMascaraSelected),callback:function ($$v) {_vm.fechaMascaraSelected=$$v},expression:"fechaMascaraSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaMascara),callback:function ($$v) {_vm.menuFechaMascara=$$v},expression:"menuFechaMascara"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaMascaraSelected = _vm.formatDate(_vm.fechaMascara)},"input":function($event){_vm.menuFechaMascara = false}},model:{value:(_vm.fechaMascara),callback:function ($$v) {_vm.fechaMascara=$$v},expression:"fechaMascara"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3"}},[_c('v-menu',{ref:"fecha-mascara-hasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":_vm.rules.required.concat(
                    _vm.rules.validDate,
                    new Date(_vm.parseDateToIso(_vm.fechaMascaraSelected)) <=
                      new Date(_vm.parseDateToIso(_vm.fechaMascaraSelectedHasta)) ||
                      'Formato incorrecto'
                  )},on:{"blur":function($event){_vm.fechaMascaraHasta = _vm.parseDateToIso(
                    _vm.fechaMascaraSelectedHasta
                  )}},model:{value:(_vm.fechaMascaraSelectedHasta),callback:function ($$v) {_vm.fechaMascaraSelectedHasta=$$v},expression:"fechaMascaraSelectedHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaMascaraHasta),callback:function ($$v) {_vm.menuFechaMascaraHasta=$$v},expression:"menuFechaMascaraHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaMascaraSelectedHasta = _vm.formatDate(_vm.fechaMascaraHasta)},"input":function($event){_vm.menuFechaMascaraHasta = false}},model:{value:(_vm.fechaMascaraHasta),callback:function ($$v) {_vm.fechaMascaraHasta=$$v},expression:"fechaMascaraHasta"}})],1)],1)],1),_c('v-row',{staticClass:"mt-n2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"7"}},[_c('v-textarea',{attrs:{"auto-grow":"","rules":[_vm.rules.maxLength(_vm.observacion, 250)],"dense":"","outlined":"","label":"Observaciones","rows":"1"},model:{value:(_vm.observacion),callback:function ($$v) {_vm.observacion=$$v},expression:"observacion"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"5"}},[_c('v-switch',{staticClass:"mt-n1",attrs:{"label":"Aplicar a todo el grupo familiar"},model:{value:(_vm.aTodoGrupoFamiliar),callback:function ($$v) {_vm.aTodoGrupoFamiliar=$$v},expression:"aTodoGrupoFamiliar"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mt-n13"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }