<template>
  <v-card flat>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        item-key="benPreId"
        hide-default-footer
        :items="medicosCabeceraAfiliado"
        class="elevation-1"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="12" md="10" align="start">
                <v-card-title class="pl-1 primary--text"
                  >Médicos de cabecera del afiliado:
                  {{ afiliadoNom }}</v-card-title
                >
              </v-col>
              <v-col cols="12" md="2" align="end" class="pt-7">
                <v-btn
                  color="primary"
                  @click="toggleModalEditMedicoCabeceraAfiliado()"
                  class="to-right"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fechaDesde`]="{ item }">
          {{ functionFormatDate(item.fechaDesde) }}
        </template>
        <template v-slot:[`item.fechaHasta`]="{ item }">
          {{ functionFormatDate(item.fechaHasta) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="toggleModalEditMedicoCabeceraAfiliado(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar registro</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteMedicoCabecera(item.benPreId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar registro</span>
          </v-tooltip>
        </template>
        <!-- Expansion de usuario -->
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
              >
            </template>
            <span>Usuarios de carga y modificación</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Fecha alta:</strong>
            {{ item.fecAlta }}
            <strong style="padding-left: 80px">Usuario alta:</strong>
            {{ item.usuAlta }}
            <strong style="padding-left: 80px">Fecha modificación:</strong>
            {{ item.fecModi }}
            <strong style="padding-left: 80px">Usuario modificación:</strong>
            {{ item.usuModi }}
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-if="modalEditMedicoCabeceraAfiliado"
        v-model="modalEditMedicoCabeceraAfiliado"
        max-width="40%"
        @keydown.esc="toggleModalEditMedicoCabeceraAfiliado()"
        persistent
      >
        <EditMedicoCabeceraAfiliado
          :benId="benId"
          :medicoCabecera="medicoCabecera"
          @toggleModalEditMedicoCabeceraAfiliado="
            toggleModalEditMedicoCabeceraAfiliado
          "
        ></EditMedicoCabeceraAfiliado>
      </v-dialog>
    </v-container>
    <v-container class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" align="end">
          <v-btn color="primary" class="to-right" @click="closeModal">
            Cerrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import moment from "moment";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import EditMedicoCabeceraAfiliado from "./EditMedicoCabeceraAfiliado.vue";
import { mapActions } from "vuex";

export default {
  components: {
    DeleteDialog,
    EditMedicoCabeceraAfiliado
  },
  name: "MedicoCabeceraAfiliado",
  props: {
    benId: { type: Number, required: true },
    afiliadoNom: { type: String, required: true }
  },

  data() {
    return {
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      benPreId: null,
      expanded: [],
      headers: [
        {
          text: "Prestador",
          align: "start",
          sortable: false,
          value: "prestadorNom"
        },
        {
          text: "Fecha desde",
          sortable: false,
          value: "fechaDesde"
        },
        {
          text: "Fecha hasta",
          sortable: false,
          value: "fechaHasta"
        },
        {
          text: "Observaciones",
          sortable: false,
          value: "observaciones"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end"
        }
      ],
      medicosCabeceraAfiliado: [],
      titleDelete: "¿Eliminar registro?",
      idToDelete: null,
      showDeleteModal: false,
      modalEditMedicoCabeceraAfiliado: false,
      medicoCabecera: null
    };
  },
  created() {
    this.setMedicosCabeceraAfiliado();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getMedicosCabeceraAfiliado: "afiliaciones/getMedicosCabeceraAfiliado",
      deleteMedicoCabeceraAfiliado: "afiliaciones/deleteMedicoCabeceraAfiliado"
    }),
    async setMedicosCabeceraAfiliado() {
      const data = await this.getMedicosCabeceraAfiliado(this.benId);
      this.medicosCabeceraAfiliado = data;
    },
    deleteMedicoCabecera(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteMedicoCabeceraAfiliado(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setMedicosCabeceraAfiliado();
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeModal() {
      this.$emit("toggleModalMedicoCabeceraAfiliado");
    },
    toggleModalEditMedicoCabeceraAfiliado(item) {
      this.modalEditMedicoCabeceraAfiliado = !this
        .modalEditMedicoCabeceraAfiliado;
      this.medicoCabecera = item;
      this.setMedicosCabeceraAfiliado();
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  }
};
</script>

<style></style>
