<template>
  <v-card>
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12" md="8" align="start" class="py-0">
          <v-card-title class="pl-1 pb-0 primary--text"
            >{{ title }}
          </v-card-title>
        </v-col>
        <v-col cols="4" align="end">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="to-right" icon @click="closeModal()">
                <v-icon id="btnCerrarLogAdhesiones" v-bind="attrs" v-on="on">{{
                  closeIcon
                }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12 " class="pb-0">
          <v-data-table
            :headers="headers"
            :items="detalleAdhesionItems"
            class="elevation-1"
            id="dataTableLogAdhesionesFormasPagoAgente"
            dense
            item-key="movFpLogId"
            :loading="loadingTable"
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length ">
                <v-row>
                  <v-col cols="10" class="py-0">
                    {{ item.mensajeError }}
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
              <span :style="!item.estadoOk ? 'color:red' : ''">
                {{ item.fecha }}</span
              >
            </template>
            <template v-slot:[`item.fPagoNom`]="{ item }">
              <span :style="!item.estadoOk ? 'color:red' : ''">
                {{ item.fPagoNom }}</span
              >
            </template>
            <template v-slot:[`item.entFinNom`]="{ item }">
              <span :style="!item.estadoOk ? 'color:red' : ''">
                {{ item.entFinNom }}</span
              >
            </template>
            <template v-slot:[`item.vigencia`]="{ item }">
              <span :style="!item.estadoOk ? 'color:red' : ''">
                {{ item.vigencia }}</span
              >
            </template>

            <template v-slot:[`item.accion`]="{ item }">
              <span :style="!item.estadoOk ? 'color:red' : ''">
                {{ item.accion }}</span
              >
            </template>
            <template v-slot:[`item.cbu`]="{ item }">
              <span :style="!item.estadoOk ? 'color:red' : ''">
                {{ item.cbu }}</span
              >
            </template>
            <template v-slot:[`item.numero`]="{ item }">
              <span :style="!item.estadoOk ? 'color:red' : ''">
                {{ item.numero }}</span
              >
            </template>
            <template v-slot:[`item.estadoOk`]="{ item }">
              <v-icon
                :style="!item.estadoOk ? 'color:red' : 'color:green'"
                small
                class="d-flex justify-center"
              >
                {{ item.estadoOk ? checkIcon : closeIcon }}
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";

export default {
  name: "LogAdhesionesFormasPagoAgente",
  props: {
    agectaId: {
      Type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: `Log de adhesiones de forma de pago`,
      detalleAdhesionItems: [],
      expanded: [],
      checkIcon: enums.icons.CHECK,
      closeIcon: enums.icons.CLOSE,

      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha",
        },
        {
          text: "Forma de pago",
          align: "start",
          sortable: false,
          value: "fPagoNom",
        },
        {
          text: "Entidad financiera",
          sortable: false,
          align: "start",
          value: "entFinNom",
        },
        {
          text: "Vigencia",
          sortable: false,
          align: "start",
          value: "vigencia",
        },
        {
          text: "Estado",
          sortable: false,
          align: "center",
          value: "estadoOk",
        },
        {
          text: "Acción",
          sortable: false,
          align: "start",
          value: "accion",
        },
        {
          text: "CBU",
          sortable: false,
          value: "cbu",
        },
        {
          text: "Número",
          sortable: false,
          value: "numero",
        },
      ],
      loadingTable: false,
      loadingReenviarBtn: false,
      errorCount: 0,
    };
  },
  created() {
    this.setDetalleProceso();
  },
  methods: {
    ...mapActions({
      getLogMovFPagoAdhesionesByAgente:
        "configuracion/getLogMovFPagoAdhesionesByAgente",
      setAlert: "user/setAlert",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    async setDetalleProceso() {
      this.loadingTable = true;
      this.detalleAdhesionItems = await this.getLogMovFPagoAdhesionesByAgente(
        this.agectaId
      );

      this.loadingTable = false;
    },
  },
};
</script>

