<template>
  <v-card>
    <v-card-title class="pt-0">
      <v-row>
        <v-col cols="10">
          <span class="primary--text">{{ formTitle }}</span>
        </v-col>
        <v-col cols="2" class="pr-2 pb-1 pt-0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="to-right text-right" icon @click="closeModal">
                <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent="saveConfig()"
      >
        <v-row class="pb-0">
          <!-- Mascaras -->
          <v-col cols="4" class="py-0 pr-0">
            <v-autocomplete
              :items="mascarasItems"
              v-model="mascaraSelected"
              label="Mascara"
              item-text="value"
              item-value="id"
              return-object
              @change="changeMascara"
              :rules="rules.required"
              outlined
              clearable
              dense
            >
            </v-autocomplete>
          </v-col>
          <!-- fecha desde -->
          <v-col cols="6" md="4" class="py-0">
            <v-menu
              ref="fecha-mascara-desde"
              v-model="menuFechaMascara"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaMascaraSelected"
                  label="Fecha desde"
                  :append-icon="calendarIcon"
                  v-bind="attrs"
                  autocomplete="not"
                  hint="Formato DD/MM/AAAA"
                  @blur="fechaMascara = parseDateToIso(fechaMascaraSelected)"
                  outlined
                  dense
                  clearable
                  v-mask="'##/##/####'"
                  v-on="on"
                  :rules="
                    rules.required.concat(
                      rules.validDate,
                      new Date(parseDateToIso(fechaMascaraSelected)) <=
                        new Date(parseDateToIso(fechaMascaraSelectedHasta)) ||
                        'Formato incorrecto'
                    )
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaMascara"
                no-title
                @change="fechaMascaraSelected = formatDate(fechaMascara)"
                @input="menuFechaMascara = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- fecha hasta -->
          <v-col cols="6" md="4" class="py-0">
            <v-menu
              ref="fecha-mascara-hasta"
              v-model="menuFechaMascaraHasta"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaMascaraSelectedHasta"
                  label="Fecha hasta"
                  :append-icon="calendarIcon"
                  v-bind="attrs"
                  autocomplete="not"
                  hint="Formato DD/MM/AAAA"
                  @blur="
                    fechaMascaraHasta = parseDateToIso(
                      fechaMascaraSelectedHasta
                    )
                  "
                  outlined
                  dense
                  clearable
                  v-mask="'##/##/####'"
                  v-on="on"
                  :rules="
                    rules.required.concat(
                      rules.validDate,
                      new Date(parseDateToIso(fechaMascaraSelected)) <=
                        new Date(parseDateToIso(fechaMascaraSelectedHasta)) ||
                        'Formato incorrecto'
                    )
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaMascaraHasta"
                no-title
                @change="
                  fechaMascaraSelectedHasta = formatDate(fechaMascaraHasta)
                "
                @input="menuFechaMascaraHasta = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-n2">
          <!-- observacion -->
          <v-col cols="7" class="py-0">
            <v-textarea
              v-model="observacion"
              auto-grow
              :rules="[rules.maxLength(observacion, 250)]"
              dense
              outlined
              label="Observaciones"
              rows="1"
            ></v-textarea>
          </v-col>
          <!-- switch 1 -->
          <v-col cols="5" class="py-0 my-0">
            <v-switch
              class="mt-n1"
              v-model="aTodoGrupoFamiliar"
              label="Aplicar a todo el grupo familiar"
            ></v-switch>
          </v-col>
        </v-row>
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :items="gruposItems"
          class="elevation-1"
          item-key="gruId"
          dense
          :hide-default-footer="gruposItems.length < 10"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="goToVerDetalleGrupo(item)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
    <v-card-actions class="mt-n3">
      <v-spacer></v-spacer>
      <v-btn type="submit" :disabled="!isFormValid" form="form" color="primary">
        Guardar
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-if="openDetalleGrupoModal"
      v-model="openDetalleGrupoModal"
      max-width="60%"
      @keydown.esc="closeAndReloadDetalle"
      persistent
    >
      <DetalleGrupoMascara
        @closeAndReloadDetalle="closeAndReloadDetalle"
        :gruId="this.gruIdToDetalle"
      ></DetalleGrupoMascara>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import DetalleGrupoMascara from "@/components/modules/afiliaciones/afiliados/DetalleGrupoMascara.vue";
export default {
  name: "HeredarMascaraAfiliado",
  directives: { mask },
  components: { DetalleGrupoMascara },
  props: {
    benId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    formTitle: "Heredar mascara",
    rules: rules,
    closeIcon: enums.icons.CLOSE,
    calendarIcon: enums.icons.CALENDAR,
    seeIcon: enums.icons.SEE,
    isFormValid: false,
    aTodoGrupoFamiliar: false,
    observacion: "",
    mascaraSelected: null,
    mascarasItems: [],
    gruposItems: [],
    gruIdToDetalle: 0,
    headers: [
      {
        text: "Grupo",
        align: "left",
        value: "gruNombre",
        sortable: false,
      },
      {
        text: "Agrupación",
        align: "left",
        value: "agrupaNombre",
        sortable: false,
      },
      {
        text: "Días",
        align: "left",
        value: "dias",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "end",
        value: "actions",
        sortable: false,
      },
    ],
    loadingTable: false,
    openDetalleGrupoModal: false,
    menuFechaMascara: false,
    menuFechaMascaraHasta: false,
    fechaMascaraSelected: null,
    fechaMascaraSelectedHasta: null,
    fechaMascara: null,
    fechaMascaraHasta: null,
  }),
  async created() {
    await this.setMascaras();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getMascarasCarencias: "afiliaciones/getMascarasCarencias",
      GetGruposByMascara: "afiliaciones/GetGruposByMascara",
      heredarMascaraPorAfiliado: "afiliaciones/heredarMascaraPorAfiliado",
    }),
    async setMascaras() {
      const data = await this.getMascarasCarencias(-1);
      this.mascarasItems = data;
    },
    async changeMascara() {
      if (this.mascaraSelected == undefined || this.mascaraSelected == null) {
        this.gruposItems = [];
      } else {
        const response = await this.GetGruposByMascara(this.mascaraSelected.id);
        this.gruposItems = response;
      }
    },
    goToVerDetalleGrupo(item) {
      this.openDetalleGrupoModal = true;
      this.gruIdToDetalle = item.gruId;
    },
    closeAndReloadDetalle() {
      this.openDetalleGrupoModal = false;
    },
    async saveConfig() {
      this.isFormValid = false;
      const data = {
        benId: this.benId,
        carenciaMascId: this.mascaraSelected.id,
        fecha: this.fechaMascara,
        fechaHasta: this.fechaMascaraHasta,
        aGrupoFamiliar: this.aTodoGrupoFamiliar,
        observa: this.observacion == null ? "" : this.observacion,
      };
      try {
        const response = await this.heredarMascaraPorAfiliado(data);
        if (response) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
</style>