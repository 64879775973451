<template>
  <v-card>
    <v-card-title class="pt-0">
      <v-row>
        <v-col cols="10">
          <span class="primary--text">{{ formTitle }}</span>
        </v-col>
        <v-col cols="2" class="pr-2 pb-1 pt-0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="to-right text-right"
                icon
                @click="closeModalDetalle"
              >
                <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="loadingTable"
        :headers="headers"
        :items="gruposItems"
        class="elevation-1"
        dense
        :hide-default-footer="gruposItems.length < 10"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
export default {
  name: "DetalleGrupoMascara",
  props: {
    gruId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    formTitle: "Configuración del grupo",
    closeIcon: enums.icons.CLOSE,
    gruposItems: [],
    headers: [
      {
        text: "Tipo de nomenclador",
        align: "left",
        value: "nomencladorNom",
        sortable: false,
      },
      {
        text: "Cód. desde",
        align: "left",
        value: "codigoDesde",
        sortable: false,
      },
      {
        text: "Práctica desde",
        align: "left",
        value: "practicaDesde",
        sortable: false,
      },
      {
        text: "Cód. hasta",
        align: "left",
        value: "codigoHasta",
        sortable: false,
      },
      {
        text: "Práctica hasta",
        align: "left",
        value: "practicaHasta",
        sortable: false,
      },
    ],
    loadingTable: false,
  }),
  async created() {
    await this.setMascaras();
  },
  methods: {
    ...mapActions({
      getConfigByGrupoMascara: "afiliaciones/getConfigByGrupoMascara",
    }),
    async setMascaras() {
        const data = await this.getConfigByGrupoMascara(this.gruId);
        this.gruposItems = data;
    },
    closeModalDetalle() {
      this.$emit("closeAndReloadDetalle");
    },
  },
};
</script>
<style scoped>
</style>