<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <v-row>
          <v-col cols="10">
            <span class="primary--text">{{ title }}</span>
          </v-col>
          <v-col cols="2" class="pr-2 pb-1 pt-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="to-right text-right" icon @click="closeModal">
                  <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Cerrar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-0 mb-0">
        <v-row class="mt-n5">
          <!-- numero -->
          <v-col
            cols="12"
            sm="6"
            md="2"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              type="text"
              outlined
              dense
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              label="Número de afiliado"
              v-model="afiliado.id"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- nombre y apellido -->
          <v-col
            cols="12"
            sm="6"
            md="5"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              v-model="afiliado.name"
              label="Apellido y nombre de afiliado"
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              outlined
              dense
              type="text"
              autocomplete="off"
            >
            </v-text-field>
          </v-col>
          <!-- Tipo de documento -->
          <v-col
            cols="12"
            sm="6"
            md="2"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input"
              label="Tipo de documento"
              v-model="afiliado.tipoDocumento"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- Número de documento -->
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input"
              label="Número de documento"
              v-model="afiliado.documento"
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :search="search"
          :items="carenciasItems"
          class="elevation-1 mt-n5"
          item-key="id"
          dense
          :hide-default-footer="carenciasItems.length < 10"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="2" align="end" class="">
                  <v-btn
                    color="primary"
                    small
                    @click="openModalNuevaCarencia()"
                    class="to-right"
                  >
                    Nueva carencia
                  </v-btn>
                </v-col>
                <v-col cols="2" align="end" class="">
                  <v-btn
                    color="primary"
                    small
                    @click="openModalHeredarMascara()"
                    class="to-right"
                  >
                    Heredar mascara
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteCarenciaAfi(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar carencia</span>
            </v-tooltip>
          </template>
          <!-- Expansion de usuario -->
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fechaAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Observación:</strong>
              {{ item.observaciones }}
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- modal heredar mascara -->
      <v-dialog
        v-if="openHeredarMascaraModal"
        v-model="openHeredarMascaraModal"
        max-width="70%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <HeredarMascaraAfiliado
          @closeAndReload="closeAndReload"
          :benId="this.afiliado.id"
        ></HeredarMascaraAfiliado>
      </v-dialog>
      <!-- modal nueva carencia -->
      <v-dialog
        v-if="openNuevaCarenciaModal"
        v-model="openNuevaCarenciaModal"
        max-width="85%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <NuevaCarenciaAfiliado
          @closeAndReload="closeAndReload"
          :benId="this.afiliado.id"
        ></NuevaCarenciaAfiliado>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import HeredarMascaraAfiliado from "@/components/modules/afiliaciones/afiliados/HeredarMascaraAfiliado.vue";
import NuevaCarenciaAfiliado from "@/components/modules/afiliaciones/afiliados/NuevaCarenciaAfiliado.vue";

export default {
  name: "CarenciasDeAfiliado",
  props: {
    afiliado: {
      type: Object,
      required: false,
    },
  },
  components: { HeredarMascaraAfiliado, NuevaCarenciaAfiliado },
  data() {
    return {
      title: "Carencias del afiliado",
      searchIcon: enums.icons.SEARCH,
      closeIcon: enums.icons.CLOSE,
      deleteIcon: enums.icons.DELETE,
      search: "",
      expanded: [],
      carenciasItems: [],
      headers: [
        {
          text: "Agrupación",
          align: "start",
          value: "agrupaNombre",
          sortable: false,
        },
        {
          text: "Grupo",
          align: "start",
          value: "gruNombre",
          sortable: false,
        },
        {
          text: "Fecha desde",
          align: "start",
          value: "fechaDesde",
          sortable: false,
        },
        {
          text: "Fecha hasta",
          align: "start",
          value: "fechaHasta",
          sortable: false,
        },
        {
          text: "Acciones",
          align: "end",
          value: "actions",
          sortable: false,
        },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end",
        },
      ],
      loadingTable: false,
      openHeredarMascaraModal: false,
      openNuevaCarenciaModal: false,
    };
  },
  async created() {
    this.loadCarencias();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      GetCarenciasDelAfiliado: "afiliaciones/GetCarenciasDelAfiliado",
      DeleteCarenciaPorAfiliado: "afiliaciones/DeleteCarenciaPorAfiliado",
    }),
    async loadCarencias() {
      this.loadingTable = true;
      const data = await this.GetCarenciasDelAfiliado(this.afiliado.id);
      this.carenciasItems = data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      this.loadingTable = false;
    },
    async deleteCarenciaAfi(item) {
      this.loadingTable = true;
      const payload = {
        benId: this.afiliado.id,
        gruId: item.gruId,
      };
      const data = await this.DeleteCarenciaPorAfiliado(payload);
      if (data) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        await this.loadCarencias();
      }
      this.loadingTable = false;
    },
    openModalNuevaCarencia() {
      this.openNuevaCarenciaModal = true;
    },
    openModalHeredarMascara() {
      this.openHeredarMascaraModal = true;
    },
    closeAndReload() {
      this.openHeredarMascaraModal = false;
      this.openNuevaCarenciaModal = false;
      this.loadCarencias();
    },
    closeModal() {
      this.$emit("toggleModalCarenciasDeAfiliado");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>