var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersFormasDePago,"item-key":"movFPagoId","hide-default-footer":"","items":_vm.formasPago},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"8","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.titleFPagoTable))])],1),_c('v-col',{staticClass:"pt-4 text-right",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.toggleModalLogAdhesiones()}}},[_vm._v(" Log de adhesiones ")])],1),_c('v-col',{staticClass:"pt-4 text-right",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleModalEditFormaPago()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.toggleModalEditFormaPago(item.movFPagoId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar forma de pago")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteFormaPago(item.movFPagoId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar forma de pago")])])]}}],null,true)}),(_vm.modalEditFormaPago)?_c('v-dialog',{attrs:{"max-width":"55%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalEditFormaPago()}},model:{value:(_vm.modalEditFormaPago),callback:function ($$v) {_vm.modalEditFormaPago=$$v},expression:"modalEditFormaPago"}},[_c('EditFormaPagoAgente',{attrs:{"idFormaPago":_vm.idFormaPago,"agectaNom":_vm.agectaNom,"agectaId":_vm.agectaId},on:{"toggleModalEditFormaPago":_vm.toggleModalEditFormaPago,"setFormasPagoAgente":_vm.setFormasPagoAgente}})],1):_vm._e()],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAlias,"item-key":"agentesCtaAliasId","hide-default-footer":"","items":_vm.agenteCtaAlias},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"10","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.titleAliasTable))])],1),_c('v-col',{staticClass:"pt-4 text-right",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleModalEditAlias()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.toggleModalEditAlias(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar alias")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteDialogAliasAgente(item.agentesCtaAliasId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar alias")])])]}}],null,true)}),(_vm.modalEditAlias)?_c('v-dialog',{attrs:{"max-width":"30%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalEditAlias()}},model:{value:(_vm.modalEditAlias),callback:function ($$v) {_vm.modalEditAlias=$$v},expression:"modalEditAlias"}},[_c('EditAliasAgente',{attrs:{"aliasAgente":_vm.aliasAgente,"agectaNom":_vm.agectaNom,"agectaId":_vm.agectaId},on:{"toggleModalEditAlias":_vm.toggleModalEditAlias}})],1):_vm._e(),(_vm.modalLogAdhesiones)?_c('v-dialog',{attrs:{"max-width":"70rem","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalLogAdhesiones()}},model:{value:(_vm.modalLogAdhesiones),callback:function ($$v) {_vm.modalLogAdhesiones=$$v},expression:"modalLogAdhesiones"}},[_c('LogAdhesionesFormasPagoAgente',{attrs:{"agectaId":_vm.agectaId},on:{"closeModal":_vm.toggleModalLogAdhesiones}})],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","align":"end"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }