var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"8","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 pb-0 primary--text"},[_vm._v(_vm._s(_vm.title)+" ")])],1),_c('v-col',{attrs:{"cols":"4","align":"end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"to-right",attrs:{"icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"id":"btnCerrarLogAdhesiones"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.closeIcon))])],1)]}}])},[_c('span',[_vm._v("Cerrar")])])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12 "}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.detalleAdhesionItems,"id":"dataTableLogAdhesionesFormasPagoAgente","dense":"","item-key":"movFpLogId","loading":_vm.loadingTable,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(item.mensajeError)+" ")])],1)],1)]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(!item.estadoOk ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.fecha))])]}},{key:"item.fPagoNom",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(!item.estadoOk ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.fPagoNom))])]}},{key:"item.entFinNom",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(!item.estadoOk ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.entFinNom))])]}},{key:"item.vigencia",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(!item.estadoOk ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.vigencia))])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(!item.estadoOk ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.accion))])]}},{key:"item.cbu",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(!item.estadoOk ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.cbu))])]}},{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(!item.estadoOk ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.numero))])]}},{key:"item.estadoOk",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"d-flex justify-center",style:(!item.estadoOk ? 'color:red' : 'color:green'),attrs:{"small":""}},[_vm._v(" "+_vm._s(item.estadoOk ? _vm.checkIcon : _vm.closeIcon)+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }