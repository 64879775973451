<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headersFormasDePago"
            item-key="movFPagoId"
            hide-default-footer
            :items="formasPago"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="8" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      titleFPagoTable
                    }}</v-card-title>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-4 text-right">
                    <v-btn
                      color="primary"
                      outlined
                      @click="toggleModalLogAdhesiones()"
                    >
                      Log de adhesiones
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-4 text-right">
                    <v-btn color="primary" @click="toggleModalEditFormaPago()">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="toggleModalEditFormaPago(item.movFPagoId)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar forma de pago</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteFormaPago(item.movFPagoId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar forma de pago</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <!-- Modal edit forma de pago -->
          <v-dialog
            v-if="modalEditFormaPago"
            v-model="modalEditFormaPago"
            max-width="55%"
            @keydown.esc="toggleModalEditFormaPago()"
            persistent
          >
            <EditFormaPagoAgente
              :idFormaPago="idFormaPago"
              :agectaNom="agectaNom"
              :agectaId="agectaId"
              @toggleModalEditFormaPago="toggleModalEditFormaPago"
              @setFormasPagoAgente="setFormasPagoAgente"
            ></EditFormaPagoAgente>
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headersAlias"
            item-key="agentesCtaAliasId"
            hide-default-footer
            :items="agenteCtaAlias"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="10" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      titleAliasTable
                    }}</v-card-title>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-4 text-right">
                    <v-btn color="primary" @click="toggleModalEditAlias()">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="toggleModalEditAlias(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar alias</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteDialogAliasAgente(item.agentesCtaAliasId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar alias</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <!-- Modal edit alias -->
          <v-dialog
            v-if="modalEditAlias"
            v-model="modalEditAlias"
            max-width="30%"
            @keydown.esc="toggleModalEditAlias()"
            persistent
          >
            <EditAliasAgente
              :aliasAgente="aliasAgente"
              :agectaNom="agectaNom"
              :agectaId="agectaId"
              @toggleModalEditAlias="toggleModalEditAlias"
            ></EditAliasAgente>
          </v-dialog>
          <v-dialog
            v-if="modalLogAdhesiones"
            v-model="modalLogAdhesiones"
            max-width="70rem"
            @keydown.esc="toggleModalLogAdhesiones()"
            persistent
          >
            <LogAdhesionesFormasPagoAgente
              :agectaId="agectaId"
              @closeModal="toggleModalLogAdhesiones"
            ></LogAdhesionesFormasPagoAgente>
          </v-dialog>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal">
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>

    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import rules from "@/utils/helpers/rules";
import EditFormaPagoAgente from "./EditFormaPagoAgente.vue";
import EditAliasAgente from "./EditAliasAgente.vue";
import LogAdhesionesFormasPagoAgente from "./LogAdhesionesFormasPagoAgente";
import enums from "@/utils/enums/index.js";

import { mapActions } from "vuex";

export default {
  components: {
    DeleteDialog,
    EditFormaPagoAgente,
    EditAliasAgente,
    LogAdhesionesFormasPagoAgente
  },
  name: "FormasPagoAgente",
  props: {
    agectaId: { type: Number, required: true, default: null },
    agectaNom: { type: String, required: true, default: null }
  },
  data() {
    return {
      titleFPagoTable: `Formas de pago del agente: ${this.agectaNom}`,
      titleAliasTable: `Alias del agente: ${this.agectaNom}`,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      rules: rules,
      formasPago: [],
      aliasAgente: null,
      titleDelete: "¿Eliminar registro?",
      showDeleteModal: false,
      idToDelete: null,
      modalEditFormaPago: false,
      modalLogAdhesiones: false,
      idFormaPago: null,
      agenteCtaAlias: [],
      modalEditAlias: false,
      headersAlias: [
        {
          text: "Tipo alias",
          align: "start",
          sortable: false,
          value: "tipoAliasNom"
        },
        {
          text: "Alias",
          sortable: false,
          value: "alias"
        },
        {
          text: "Vigencia desde",
          sortable: false,
          value: "vigenciaDesde"
        },
        {
          text: "Vigencia hasta",
          sortable: false,
          value: "vigenciaHasta"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      headersFormasDePago: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: false,
          value: "fPagoNom"
        },
        {
          text: "Entidad",
          sortable: false,
          value: "entFinNom"
        },
        {
          text: "Período desde",
          sortable: false,
          value: "periodoDesde"
        },
        {
          text: "Período hasta",
          sortable: false,
          value: "periodoHasta"
        },
        {
          text: "CBU",
          sortable: false,
          value: "cbu"
        },
        {
          text: "Número de cuenta",
          sortable: false,
          value: "numeroCuenta"
        },
        {
          text: "Vencimiento",
          sortable: false,
          value: "vencimiento"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      tableToDelete: null
    };
  },
  created() {
    this.setFormasPagoAgente();
    this.setAliasAgente();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getFormasPagoAgente: "agenteCuenta/getFormasPagoAgente",
      deleteFormaPagoAgente: "agenteCuenta/deleteFormaPagoAgente",
      getAliasAgente: "agenteCuenta/getAliasAgente",
      deleteAliasAgente: "agenteCuenta/deleteAliasAgente"
    }),
    deleteDialogAliasAgente(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
      this.tableToDelete = "Alias";
    },
    deleteFormaPago(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
      this.tableToDelete = "FormaPago";
    },
    async confirmDelete() {
      switch (this.tableToDelete) {
        case "FormaPago":
          const formaPago = await this.deleteFormaPagoAgente(this.idToDelete);
          if (formaPago.status === 200) {
            this.showDeleteModal = false;
            this.setAlert({ type: "success", message: "Eliminado con éxito." });
            this.setFormasPagoAgente();
          }
          break;
        case "Alias":
          const alias = await this.deleteAliasAgente(this.idToDelete);
          if (alias.status === 200) {
            this.showDeleteModal = false;
            this.setAlert({ type: "success", message: "Eliminado con éxito." });
            this.setAliasAgente();
          }
          break;
        default:
          break;
      }
    },
    async setFormasPagoAgente() {
      const data = await this.getFormasPagoAgente(this.agectaId);
      this.formasPago = data;
    },
    async setAliasAgente() {
      const data = await this.getAliasAgente(this.agectaId);
      this.agenteCtaAlias = data;
    },
    closeModal() {
      this.$emit("toggleModalEditFormaPago");
      this.$emit("toggleModalFormasPagoAgente");
      this.$emit("toggleModalEditAlias");
    },
    toggleModalEditFormaPago(id) {
      this.idFormaPago = id;
      this.modalEditFormaPago = !this.modalEditFormaPago;
      !this.modalEditFormaPago ? this.setFormasPagoAgente() : [];
    },
    toggleModalEditAlias(item) {
      this.aliasAgente = item;
      this.modalEditAlias = !this.modalEditAlias;
      !this.modalEditAlias ? this.setAliasAgente() : [];
    },
    toggleModalLogAdhesiones() {
      this.modalLogAdhesiones = !this.modalLogAdhesiones;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
